import React from 'react';
import cx from 'classnames';
import classes from './SelectField.module.scss';

function SelectField({ label, options, blankOption, value, onChange, ...otherProps }) {
  return (
    <div className={classes.container}>
      <label className={classes.label}>{label}</label>
      <div className={classes.selectContainer}>
        <select
          className={cx(classes.select, { [classes.invalid]: !value })}
          value={value || ''}
          onChange={onChange}
          {...otherProps}
        >
          {blankOption && <option value='' disabled>{blankOption}</option>}
          {options.map(({ value, label }) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default SelectField;