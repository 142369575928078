import React from 'react';
import TextField from 'features/common/ui/Field/TextField';
import LinkField from 'features/common/ui/Field/LinkField';

const FIELD_COMPONENTS = {
  'text': TextField,
  'link': LinkField
};

function VectorTilesetFeature({ feature: { layer, properties } }) {
  return (
    <div>
      {layer.fields ? layer.fields.map(({ key, type, label }) => {
        const FieldComponent = FIELD_COMPONENTS[type];
        return (
          <FieldComponent
            key={key}
            label={label}
            value={properties[key]}
          />
        )
      }) : Object.entries(properties).map(([key, value]) => (
        <TextField
          key={key}
          label={key}
          value={value}
        />
      ))}
    </div>
  );
}

export default VectorTilesetFeature;