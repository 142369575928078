import React from 'react';
import {
  TIPOLOGIA_AGENTE_PATRIMONIAL_LIST, ACTIVIDAD_AGENTE_PATRIMONIAL_LIST,
  NATURALEZA_ACTIVIDAD_AGENTE_LIST
} from 'config/layers';
import useProvincesMunicipalities from '../../hooks/useProvincesMunicipalities';
import useFeatureEditHandlers from './useFeatureEditHandlers';
import LocationEditor from './LocationEditor';
import InputField from 'features/common/ui/Field/InputField';
import SelectField from 'features/common/ui/Field/SelectField';
import TextAreaField from 'features/common/ui/Field/TextAreaField';
import ImageInputField from 'features/common/ui/Field/ImageInputField';

function AgentePatrimonialEdit({ data, setData, saveFeature }) {
  const {
    handleFieldChange, handleProvinceChange, handleMunicipalityChange,
    handleImageChange, handleImageDelete, handleLocationChange, handleSubmit
  } = useFeatureEditHandlers(setData, saveFeature);

  const { provinces, municipalities } = useProvincesMunicipalities(
    data.provinciaId, data.municipioId
  );
  
  return (
    <form id='feature-form' onSubmit={handleSubmit}>
      <LocationEditor value={data.geom} onChange={handleLocationChange} />
      <InputField
        name='nombre'
        label='Nombre'
        value={data.nombre}
        onChange={handleFieldChange}
        required
      />
      <SelectField
        name='tipologia'
        label='Tipología'
        value={data.tipologia}
        options={TIPOLOGIA_AGENTE_PATRIMONIAL_LIST}
        blankOption='Selecciona una tipología'
        onChange={handleFieldChange}
        required
      />
      <SelectField
        name='actividad'
        label='Actividad'
        value={data.actividad}
        options={ACTIVIDAD_AGENTE_PATRIMONIAL_LIST}
        blankOption='Selecciona una actividad'
        onChange={handleFieldChange}
        required
      />
      <SelectField
        name='naturaleza'
        label='Naturaleza'
        value={data.naturaleza}
        options={NATURALEZA_ACTIVIDAD_AGENTE_LIST}
        blankOption='Selecciona la naturaleza de la actividad'
        onChange={handleFieldChange}
        required
      />
      <SelectField
        name='provincia'
        label='Provincia'
        value={data.provinciaId}
        options={provinces || []}
        blankOption='Selecciona una provincia'
        onChange={handleProvinceChange}
        required
      />
      <SelectField
        name='municipio'
        label='Municipio'
        value={data.municipioId}
        options={municipalities || []}
        blankOption='Selecciona un municipio'
        onChange={handleMunicipalityChange}
        required
      />
      <InputField
        name='direccionPostal'
        label='Dirección postal'
        value={data.direccionPostal}
        onChange={handleFieldChange}
      />
      <InputField
        name='url'
        label='URL'
        value={data.url}
        onChange={handleFieldChange}
      />
      <TextAreaField
        name='comentarios'
        label='Comentarios'
        value={data.comentarios}
        onChange={handleFieldChange}
      />
      <ImageInputField
        label='Fotografía'
        value={data.fotografia.url}
        onChange={handleImageChange}
        onDelete={handleImageDelete}
      />
      <input type='submit' style={{ display: 'none' }}/>
    </form>
  );
}

export default AgentePatrimonialEdit;