import React from 'react';
import cx from 'classnames';
import classes from './Button.module.scss';

function Button(props) {
  const {
    children,
    type = 'button',
    color = 'primary',
    size = 'normal',
    fullwidth = false,
    center = false,
    sending = false,
    disabled = false,
    onClick = () => {}
  } = props;
  
  return (
    <button
      type={type}
      className={cx(classes.button, classes[color], classes[size], {
        [classes.sending]: sending,
        [classes.fullwidth]: fullwidth,
        [classes.center]: center
      })}
      disabled={disabled}
      onClick={onClick}
    > 
      {children}
    </button>
  );
}

export default Button;