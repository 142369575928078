import axios from 'axios';
import api from 'apiService';
import { MAP_ID } from 'config/main';

/**
 * PROJECTS SERVICE
 */
// GET Full Map
async function getFullMap() {
	const res = await api.get(`/maps/${MAP_ID}`);
	return res.data;
}

// GET Feature
async function getFeature(dataSourceUrl, featureId) {
  const res = await api.get(dataSourceUrl + '/' + featureId);
  return res.data;
}

// POST Add Feature
async function addFeature(dataSourceUrl, data) {
  const res = await api.post(dataSourceUrl, data);
  return res.data;
}

// PUT Update Feature
async function updateFeature(dataSourceUrl, featureId, data) {
  const res = await api.put(dataSourceUrl + '/' + featureId, data);
  return res.data;
}

// POST Add Feature Image
async function addFeatureImage(dataSourceUrl, featureId, data) {
  const res = await api.post(`${dataSourceUrl}/${featureId}/image`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return res.data;
}

// DELETE Delete Feature Image
async function deleteFeatureImage(dataSourceUrl, featureId) {
  const res = await api.delete(`${dataSourceUrl}/${featureId}/image`);
  return res.data;
}

// DELETE Delete Feature
async function deleteFeature(dataSourceUrl, featureId) {
  await api.delete(dataSourceUrl + '/' + featureId);
}

// GET Provinces
async function getProvinces() {
  const res = await api.get('/provincias');
  return res.data;
}

// GET Municipalities
async function getMunicipalities(provId) {
  const res = await api.get(`/provincias/${provId}/municipios`);
  return res.data;
}

// GET Mapbox Style
async function getMapboxStyle(styleId, access_token) {
  const url = `https://api.mapbox.com/styles/v1/${styleId}?access_token=${access_token}`;
  const res = await axios.get(url);
  return res.data;
}

const mapService = {
	getFullMap,
  getFeature,
  addFeature,
  updateFeature,
  addFeatureImage,
  deleteFeatureImage,
  deleteFeature,
  getProvinces,
  getMunicipalities,
  getMapboxStyle
};

export default mapService;