import React, { useState } from 'react';
import cx from 'classnames';
import classes from './LeftPanel.module.scss';
import Layers from '../Layers/Layers';
import UserMenu from 'features/auth/UserMenu';
import { ReactComponent as TriangleIcon } from 'assets/svg/triangle.svg';
import logo from 'assets/svg/sinpar-logo.svg';

function LeftPanel(props) {
  const [panelOpen, setPanelOpen] = useState(true);
  const [panelButtonHover, setPanelButtonHover] = useState(false);

  function handlePanelButtonClick() {
    setPanelOpen(state => !state);
    setPanelButtonHover(false);
  }

	return (
  	<div className={cx(classes.panel, {
      [classes.open]: panelOpen
    })}>
      <button
        className={cx(classes.panelButton, {
          [classes.open]: panelOpen,
          [classes.hover]: panelButtonHover
        })}
        onClick={handlePanelButtonClick}
        onMouseEnter={() => setPanelButtonHover(true)}
        onMouseLeave={() => setPanelButtonHover(false)}
      >
        <TriangleIcon className={classes.triangleIcon} />
      </button>
      <div className={classes.panelMain}>
        <div className={classes.panelHeader}>
          <div className={classes.companyHeader}>
            <img className={classes.logo} src={logo} alt='logo' />
            <span>Visor de Patrimonio Rural</span>
            <UserMenu />
          </div>
        </div>
        <div className={classes.separator} />
        <div className={classes.panelBody}>
          <Layers />
        </div>
  		</div>
    </div>
  );
}

export default LeftPanel;