import api from '../../apiService';

/**
 * AUTHENTICATION SERVICE
 */

// POST Login
async function login(data) {
	const res = await api.post('/auth/login', data, { withCredentials: true });
	api.defaults.headers['Authorization'] = `Bearer ${res.data.accessToken}`;
	return res.data;
}

// POST Logout
async function logout() {
  await api.post('/auth/logout', {}, { withCredentials: true });
  delete api.defaults.headers['Authorization'];
  return;
}

// POST Refresh
async function refresh({ withUser } = {}) {
  const url = withUser ? '/auth/refresh?withUser=true' : '/auth/refresh';
	const res = await api.post(url, {}, { withCredentials: true });
	api.defaults.headers['Authorization'] = `Bearer ${res.data.accessToken}`;
	return res.data;
}

// POST Register
async function register(data) {
  const res = await api.post('/auth/register', data);
  return res.data;
}

// POST Verify Registration
async function verifyRegistration(data) {
  return await api.post('/auth/register/verify', data);
}

// POST Password Recovery
async function recoverPassword(data) {
	const res = await api.post('/auth/password-recovery', data);
	return res.data;
}

// POST Verify Password Reset
async function verifyPasswordReset(data) {
	return await api.post('/auth/password-reset/verify', data);
}

// POST Reset Password
async function resetPassword(data) {
	const res = await api.post('/auth/password-reset', data, { withCredentials: true });
  api.defaults.headers['Authorization'] = `Bearer ${res.data.accessToken}`;
	return res.data;
}

// GET Current User
async function getCurrentUser() {
	const res = await api.get('/auth/me');
	return res.data;
}

const authService = {
	login,
	logout,
	refresh,
  register,
  verifyRegistration,
	recoverPassword,
	verifyPasswordReset,
	resetPassword,
	getCurrentUser
};

export default authService;