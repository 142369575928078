import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import {
  selectAuthenticated, selectUser, setAuthModal, logout
} from 'features/auth/authSlice';
import Menu from 'features/common/ui/Menu/Menu';
import MenuItem from 'features/common/ui/Menu/MenuItem';
import classes from './UserMenu.module.scss';
import avatarIcon from 'assets/svg/avatar.svg';

function UserMenu({ menuType = 'bottom' }) {
  const authenticated = useSelector(selectAuthenticated);
  const user = useSelector(selectUser);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const dispatch = useDispatch();

  function handleAvatarButtonClick() {
    if (authenticated) {
      setUserMenuOpen(state => !state);
    } else {
      dispatch(setAuthModal('login'));
    }
  }

  return (
    <div className={classes.container}>
      <button
        className={cx(classes.avatarButton, {
          [classes.authenticated]: authenticated
        })}
        onClick={handleAvatarButtonClick}
      >
        <img className={classes.avatar} src={avatarIcon} alt='avatar' />
      </button>
      <Menu
        type={menuType}
        width='150px'
        open={userMenuOpen}
        close={() => setUserMenuOpen(false)}
      >
        <MenuItem header>
          <div>{user?.username}</div>
          {(user?.role === 'admin') && 
            <div className={classes.adminUserSubheader}>(admin)</div>
          }
        </MenuItem>
        <MenuItem onClick={() => dispatch(logout())}>
          Cerrar sesión
        </MenuItem>
      </Menu>
    </div>
  );
}

export default UserMenu;