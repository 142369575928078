import React from 'react';
import classes from './FieldCommon.module.scss';

function InputField({ label, value, onChange, ...otherProps }) {
  return (
    <div className={classes.container}>
      <label className={classes.label}>{label}</label>
      <input
        className={classes.input}
        type='text'
        value={value || ''}
        onChange={onChange}
        {...otherProps}
      />
    </div>
  );
}

export default InputField;