import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectNotification, setNotification } from 'features/map/mapSlice';
import cx from 'classnames';
import classes from './Notification.module.scss';
import errorIcon from 'assets/svg/error.svg';

const NOTIFICATION_TIME = 5000;

function Notification(props) {
  const notification = useSelector(selectNotification);
  const dispatch = useDispatch();
	
  const timer = useRef(null);

	useEffect(() => {
		if (timer.current) {
			clearTimeout(timer.current);
			timer.current = null;
		}

		if (notification) {
			timer.current = setTimeout(async () => {
				timer.current = null;
				dispatch(setNotification(null));
			}, NOTIFICATION_TIME);
		}
	}, [notification, dispatch]);

  return (
  	<div
      className={cx(classes.notification, { [classes.show]: notification })}
      onClick={() => dispatch(setNotification(null))}
    >
  		{notification && (
        <>
          {notification.isError && (
            <img className={classes.errorIcon} src={errorIcon} alt='error' />
          )}
    			<p className={cx(classes.message, { [classes.error]: notification.isError})}>
            {notification.message}
          </p>
        </>
  		)}
  	</div>
  );
}

export default Notification;