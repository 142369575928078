import React from 'react';
import cx from 'classnames';
import classes from './Spinner.module.scss';

function Spinner({ show=true, size=50, marginTop=0  }) {
  return (
  	<div
      className={classes.container}
      style={{ marginTop: marginTop }}
    >
  		<div
  			className={cx(classes.spinner, { [classes.show]: show })}
  			style={{ width: size, height: size }}
  		>
			  <div className={classes.spinnerDot}></div>
			  <div className={classes.spinnerDot}></div>
			  <div className={classes.spinnerDot}></div>
			  <div className={classes.spinnerDot}></div>
			  <div className={classes.spinnerDot}></div>
			  <div className={classes.spinnerDot}></div>
			</div>
  	</div>
  );
}

export default Spinner;