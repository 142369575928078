import React from 'react';
import classes from './FeatureAction.module.scss';

function FeatureAction({ icon, submitForm, onClick, height = null }) {
  return (
    <button
      form={submitForm}
      className={classes.action}
      style={{ height }}
      onClick={onClick}
    >
      <img className={classes.actionIcon} src={icon} alt='action' />
    </button>
  );
}

export default FeatureAction;