import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  addOrUpdateFeature, setSelectedFeature, setIsEditingFeature, setNotification
} from '../../mapSlice';
import { RQ_STATUS, ERRORS } from 'config/main';
import { EDIT_FEATURE_PARSERS, NEW_FEATURE_INITIAL_DATA } from 'utils/feature';
import FeatureLayout from '../FeatureLayout/FeatureLayout';
import FeatureHeader from '../FeatureHeader/FeatureHeader';
import FeatureAction from '../FeatureHeader/FeatureAction';
import BienInmuebleEdit from './BienInmuebleEdit';
import BienInmaterialEdit from './BienInmaterialEdit';
import AgentePatrimonialEdit from './AgentePatrimonialEdit';
import saveIcon from 'assets/svg/check.svg';
import cancelIcon from 'assets/svg/x.svg';

const DATASET_FEATURE_EDIT_COMPONENTS = {
  'bien-inmueble': BienInmuebleEdit,
  'bien-inmaterial': BienInmaterialEdit,
  'agente-patrimonial': AgentePatrimonialEdit
};

function initializeEditData(selectedFeature, featureData) {
  if (selectedFeature && !selectedFeature.isNew) {
    const featureParser = EDIT_FEATURE_PARSERS[selectedFeature.layer.id];
    if (featureParser) {
      return featureParser(featureData);
    }
  } else if (selectedFeature.isNew) {
    return NEW_FEATURE_INITIAL_DATA[selectedFeature.layer.id] || {};
  }
  return {};
}

function FeatureEdit({ selectedFeature, featureStatus, featureData }) {
  const [editData, setEditData] = useState(() =>
    initializeEditData(selectedFeature, featureData)
  );
  const dispatch = useDispatch();

  function cancelEdit() {
    if (selectedFeature.isNew) {
      dispatch(setSelectedFeature(null));
    } else {
      dispatch(setIsEditingFeature(false));
    }
  }

  async function saveFeature() {
    if (!editData.geom) {
      return dispatch(setNotification({
        message: ERRORS.MAP['feature-no-location'],
        isError: true
      }));
    }
    const featureId = await dispatch(addOrUpdateFeature({
      isUpdate: !selectedFeature.isNew,
      dataSource: selectedFeature.source,
      featureId: selectedFeature.id,
      featureData: editData
    }));
    if (featureId) {
      if (selectedFeature.isNew) {
        dispatch(setSelectedFeature({
          id: featureId,
          layer: selectedFeature.layer.id,
          source: selectedFeature.source.id
        }));
      } else {
        dispatch(setIsEditingFeature(false));
      }
    }
  }

  const DatasetFeatureEdit = DATASET_FEATURE_EDIT_COMPONENTS[selectedFeature.layer.id];
  if (DatasetFeatureEdit) {
    return (
      <FeatureLayout title='Editar el elemento'>
        <FeatureHeader
          title={selectedFeature.layer.name}
          loading={featureStatus === RQ_STATUS.PENDING}
        >
          <FeatureAction submitForm='feature-form' icon={saveIcon}/>
          <FeatureAction icon={cancelIcon} height='12px' onClick={cancelEdit} />
        </FeatureHeader>
        <DatasetFeatureEdit
          data={editData}
          setData={setEditData}
          saveFeature={() => saveFeature(editData)}
        />
      </FeatureLayout>
    );
  }
}

export default FeatureEdit;