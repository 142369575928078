import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectMapStatus, selectIsMobile, fetchFullMap, deleteMap
} from '../mapSlice';
import { selectAuthModal, checkAuth } from 'features/auth/authSlice';
import useMediaQueries from '../hooks/useMediaQueries';
import classes from './MapContainer.module.scss';
import Map from '../Map/Map';
import LeftPanel from '../Panels/LeftPanel';
import RightPanel from '../Panels/RightPanel';
import MobilePanel from '../Panels/MobilePanel';
import AuthModal from 'features/auth/AuthModal';
// import MobilePanel from '../Mobile/MobilePanel';
// import MapControls from '../MapControls/MapControls';
import Notification from 'features/common/ui/Notification/Notification';
import Spinner from 'features/common/ui/Spinner/Spinner';
import NotFound from 'features/common/routing/NotFound';
import ConfirmModal from 'features/common/ui/Modal/ConfirmModal';

function MapContainer(props) {
  const mapStatus = useSelector(selectMapStatus);
  const authModal = useSelector(selectAuthModal);
  const isMobile = useSelector(selectIsMobile);
	const dispatch = useDispatch();

  useMediaQueries();

	useEffect(() => {
    dispatch(checkAuth());
    dispatch(fetchFullMap());
    
    /* Clean-up */
    return () => {
      dispatch(deleteMap());
    };
	}, [dispatch]);

  if (mapStatus === 'loaded') {
    return (
      <div className={classes.mapContainer}>
        <Map />
        {isMobile ? (
          <MobilePanel />
        ) : (
          <>
            <LeftPanel />
            <RightPanel />
          </>
        )}
        {authModal && <AuthModal />}
        <Notification />
        <ConfirmModal />
      </div>
    );
  } else if (mapStatus === 'loading') {
    return <Spinner />;
  } else {
    return <NotFound />;
  }
}

export default MapContainer;