import React from 'react';
import { useDispatch } from 'react-redux';
import { setIsEditingFeature, setConfirmModal } from '../../mapSlice';
import classes from './FeatureView.module.scss';
import { RQ_STATUS, ERRORS, MESSAGES } from 'config/main';
import FeatureLayout from '../FeatureLayout/FeatureLayout';
import FeatureHeader from '../FeatureHeader/FeatureHeader';
import FeatureAction from '../FeatureHeader/FeatureAction';
import VectorTilesetFeature from './VectorTilesetFeature';
import BienInmuebleView from './BienInmuebleView';
import BienInmaterialView from './BienInmaterialView';
import AgentePatrimonialView from './AgentePatrimonialView';
import editIcon from 'assets/svg/pencil.svg';
import deleteIcon from 'assets/svg/trash.svg';

const DATASET_FEATURE_COMPONENTS = {
  'bien-inmueble': BienInmuebleView,
  'bien-inmaterial': BienInmaterialView,
  'agente-patrimonial': AgentePatrimonialView
};

function FeatureView({ selectedFeature, featureStatus, featureData, canEditData }) {
  const dispatch = useDispatch();

  function deleteFeature() {
    dispatch(setConfirmModal({
      message: MESSAGES.MAP['delete-feature-confirm'],
      okLabel: 'Eliminar',
      confirmAction: 'deleteFeature',
      confirmPayload: {
        dataSource: selectedFeature.source,
        featureId: selectedFeature.id
      }
    }));
  }

  const isDataset = selectedFeature.source?.type === 'dataset';
  let feature = null;
  if (isDataset) {
    const DatasetFeature = DATASET_FEATURE_COMPONENTS[selectedFeature.layer.id];
    if (DatasetFeature && featureData) {
      feature = <DatasetFeature data={featureData} />;
    } else if (featureStatus === RQ_STATUS.ERROR) {
      feature = <p className={classes.errorMessage}>{ERRORS.MAP['feature-error']}</p>;
    }
  } else {
    feature = <VectorTilesetFeature feature={selectedFeature} />;
  }

  return (
    <FeatureLayout title='Información del elemento'>
      <FeatureHeader title={selectedFeature.layer?.name}>
        {isDataset && canEditData && (
          <>
            <FeatureAction
              icon={editIcon}
              onClick={() => dispatch(setIsEditingFeature(true))}
            />
            <FeatureAction icon={deleteIcon} height='14.5px' onClick={deleteFeature} />
          </>
        )}
      </FeatureHeader>
      {feature}
    </FeatureLayout>
  );
}

export default FeatureView;