import { createSlice } from '@reduxjs/toolkit';
import authService from './authService';
import { resetFeatureData } from 'features/map/mapSlice';
import { SECONDS_BEFORE_TOKEN_EXP } from 'config/main';

let silentRefreshTimerId = null;

const initialState = {
  authChecked: false,
  authenticated: false,
  user: {},
  authModal: null
};

/**************************
 * Auth Slice
 **************************/
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthChecked: (state, action) => {
      state.authChecked = action.payload;
    },
    authenticate: (state, action) => {
      state.authenticated = action.payload.authenticated;
      if (state.authenticated) {
        state.user = action.payload.user;
      } else {
        state.user = {};
        silentRefreshTimerId = null;
      }
    },
    setAuthModal: (state, action) => {
      state.authModal = action.payload;
    }
  }
})

export default authSlice.reducer;
export const {
  setAuthChecked, authenticate, setAuthModalShown, setAuthModal
} = authSlice.actions;


/**************************
 * Selector Functions
 **************************/
export const selectAuth = state => state.auth;
export const selectIsAuthChecked = state => state.auth.authChecked;
export const selectAuthenticated = state => state.auth.authenticated;
export const selectUser = state => state.auth.user;
export const selectIsAdmin = state => state.auth.user?.role === 'admin';
export const selectAuthModal = state => state.auth.authModal;


/**************************
 * Async Action Creators
 **************************/
export const loginUser = data => async dispatch => {
  const { user, expiresIn } = await authService.login(data);
  dispatch(authenticate({ authenticated: true, user }));
  dispatch(resetFeatureData());
  setSilentRefreshTimer(expiresIn, dispatch);
};

export const checkAuth = () => async dispatch => {
  try {
    const { user, expiresIn} = await authService.refresh({ withUser: true });
    dispatch(authenticate({ authenticated: true, user }));
    dispatch(resetFeatureData());
    setSilentRefreshTimer(expiresIn, dispatch);
  } catch (err) {
    dispatch(authenticate({ authenticated: false }));
    dispatch(resetFeatureData());
  } finally {
    dispatch(setAuthChecked(true));
  }
};

export const refreshToken = () => async dispatch => {
  try {
    const { expiresIn} = await authService.refresh();
    setSilentRefreshTimer(expiresIn, dispatch);
  } catch (err) {
    dispatch(logout());
  }
};

export const logout = () => async dispatch => {
  await authService.logout();
  if (silentRefreshTimerId) clearTimeout(silentRefreshTimerId);  
  dispatch(authenticate({ authenticated: false }));
  dispatch(resetFeatureData());
};

export const resetPassword = data => async dispatch => {
  const { user, expiresIn } = await authService.resetPassword(data);
  dispatch(authenticate({ authenticated: true, user }));
  dispatch(resetFeatureData());
  setSilentRefreshTimer(expiresIn, dispatch);
};


/**************************
 * Utility Functions
 **************************/
const setSilentRefreshTimer = (expiresIn, dispatch) => {
  const refreshInMs = (expiresIn - SECONDS_BEFORE_TOKEN_EXP) * 1000;
  if (silentRefreshTimerId) clearTimeout(silentRefreshTimerId);
  silentRefreshTimerId = setTimeout(() => dispatch(refreshToken()), refreshInMs);
};
