import { useLayoutEffect } from 'react';

function useShiftMapboxControls(panelOpen) {
  useLayoutEffect(() => {
    const mapboxControls = document.querySelector('.mapboxgl-ctrl-top-right');
    if (!mapboxControls) return;
    
    if (panelOpen) {
      mapboxControls.classList.add('panelOpen');
    } else {
      mapboxControls.classList.remove('panelOpen');
    }
  }, [panelOpen]);
}

export default useShiftMapboxControls;