export const TIPOLOGIA_BIEN_INMUEBLE = {
  'arquitectura-civil': 'Arquitectura civil',
  'arquitectura-contemporanea': 'Arquitectura contemporánea',
  'arquitectura-defensiva': 'Arquitectura defensiva',
  'arquitectura-religiosa': 'Arquitectura religiosa',
  'arquitectura-vernacula': 'Arquitectura vernácula',
  'patrimonio-industrial': 'Patrimonio industrial',
  'yacimientos-arqueologicos': 'Yacimientos arqueológicos',
  'infraestructuras-hidraulicas': 'Infraestructuras hidráulicas',
  'otras-infraestructuras': 'Otras infraestructuras'
};
export const TIPOLOGIA_BIEN_INMUEBLE_LIST = [
  'arquitectura-civil', 'arquitectura-contemporanea', 'arquitectura-defensiva',
  'arquitectura-religiosa', 'arquitectura-vernacula', 'patrimonio-industrial',
  'yacimientos-arqueologicos', 'infraestructuras-hidraulicas', 'otras-infraestructuras'
].map(value => ({ value, label: TIPOLOGIA_BIEN_INMUEBLE[value] }));

export const TIPOLOGIA_BIEN_INMATERIAL = {
  'alimentacion': 'Alimentación y sistemas culinarios',
  'expresion': 'Modos de expresión',
  'oficios': 'Oficios y saberes',
  'festivos': 'Rituales festivos',
  'sociabilidad': 'Sociabilidad y asociacionismo'
};
export const TIPOLOGIA_BIEN_INMATERIAL_LIST = [
  'alimentacion', 'expresion', 'oficios', 'festivos', 'sociabilidad'
].map(value => ({ value, label: TIPOLOGIA_BIEN_INMATERIAL[value] }));

export const PERIODO_HISTORICO = {
  'prehistoria': 'Prehistoria',
  'edad-antigua': 'Edad Antigua',
  'edad-media': 'Edad Media',
  'edad-moderna': 'Edad Moderna',
  'edad-contemporanea': 'Edad Contemporánea'
};
export const PERIODO_HISTORICO_LIST = [
  'prehistoria', 'edad-antigua', 'edad-media', 'edad-moderna', 'edad-contemporanea'
].map(value => ({ value, label: PERIODO_HISTORICO[value] }));

export const NIVEL_PROTECCION = {
  'bic': 'Bien de Interés Cultural',
  'cg': 'Catalogación General',
  'cmu': 'Catálogo municipal urbanístico'
};
export const NIVEL_PROTECCION_LIST = [
  'bic', 'cg', 'cmu'
].map(value => ({ value, label: NIVEL_PROTECCION[value] }));

export const TIPOLOGIA_AGENTE_PATRIMONIAL = {
  'administracion': 'Administración',
  'academico': 'Académico',
  'empresa': 'Empresa',
  'asociacion': 'Asociación',
  'fundacion': 'Fundación',
  'industrial': 'Individual',
  'otras': 'Otras'
};
export const TIPOLOGIA_AGENTE_PATRIMONIAL_LIST = [
  'administracion', 'academico', 'empresa', 'asociacion',
  'fundacion', 'industrial', 'otras'
].map(value => ({ value, label: TIPOLOGIA_AGENTE_PATRIMONIAL[value] }));

export const ACTIVIDAD_AGENTE_PATRIMONIAL = {
  'turismo': 'Turismo',
  'artesania': 'Artesanía',
  'desarrollo-local': 'Desarrollo local',
  'naturaleza': 'Naturaleza',
  'gastronomia': 'Gastronomía',
  'agricultura': 'Agricultura',
  'gestion': 'Gestión',
  'administracion': 'Administración',
  'musealizacion': 'Musealización',
  'dinamizacion': 'Dinamización',
  'investigacion': 'Investigación',
  'arqueologia': 'Arqueología',
  'arquitectura': 'Arquitectura/Construcción',
  'cultura': 'Cultura',
  'arte': 'Arte',
  'otra': 'Otra'
};
export const ACTIVIDAD_AGENTE_PATRIMONIAL_LIST = [
  'turismo', 'artesania', 'desarrollo-local', 'naturaleza', 'gastronomia',
  'agricultura', 'gestion', 'administracion', 'musealizacion', 'dinamizacion',
  'investigacion', 'arqueologia', 'arquitectura', 'cultura', 'arte', 'otra'
].map(value => ({ value, label: ACTIVIDAD_AGENTE_PATRIMONIAL[value] }));

export const NATURALEZA_ACTIVIDAD_AGENTE = {
  'publica': 'Pública',
  'privada': 'Privada',
  'sin-animo-lucro': 'Sin ánimo de lucro',
  'otra': 'Otra'
};
export const NATURALEZA_ACTIVIDAD_AGENTE_LIST = [
  'publica', 'privada', 'sin-animo-lucro', 'otra',
].map(value => ({ value, label: NATURALEZA_ACTIVIDAD_AGENTE[value] }));
