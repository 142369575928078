import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import { selectAuthModal, loginUser, setAuthModal, resetPassword } from './authSlice';
import authService from './authService';
import classes from './AuthModal.module.scss';
import Button from 'features/common/ui/Button/Button';
import { ERRORS, MESSAGES } from 'config/main';

const AUTH_MODAL_TITLE = {
  'login': 'Inicio de sesión',
  'register': 'Registro',
  'password-recovery': 'Restablecer la contraseña',
  'password-reset': 'Cambiar la contraseña'
};

const AUTH_FORM_FIELDS = {
  'login': [
    { key: 'usernameOrEmail', label: 'Nombre de usuario o email', config: { type: 'text', required: true }},
    { key: 'password', label: 'Contraseña', config: { type: 'password', required: true, minLength: 8 }}
  ],
  'register': [
    { key: 'username', label: 'Nombre de usuario', config: { type: 'text', required: true }},
    { key: 'email', label: 'Email', config: { type: 'email', required: true }},
    { key: 'password', label: 'Contraseña', config: { type: 'password', required: true, minLength: 8 }},
    { key: 'password2', label: 'Confirmar contraseña', config: { type: 'password', required: true, minLength: 8 }}
  ],
  'password-recovery': [
    { key: 'email', label: 'Email', config: { type: 'email', required: true }}
  ],
  'password-reset': [
    { key: 'password', label: 'Nueva contraseña', config: { type: 'password', required: true, minLength: 8 }},
    { key: 'password2', label: 'Confirmar contraseña', config: { type: 'password', required: true, minLength: 8 }}
  ]
};

const AUTH_FORM_LINK = {
  'login': '¿Has olvidado tu contraseña?',
  'password-recovery': 'Volver atrás'
};

const AUTH_BUTTON_LABEL = {
  'login': 'Iniciar sesión',
  'register': 'Crear cuenta',
  'password-recovery': 'Enviar enlace',
  'password-reset': 'Enviar'
};

function AuthModal(props) {
  const authModal = useSelector(selectAuthModal);
  const [authData, setAuthData] = useState({});
  const [sending, setSending] = useState(false);
  const [error, setError] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const { state: routeState } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    setAuthData({});
    setError(null);
    setConfirmation(null);
  }, [authModal]);

  const renderModalTitle = () => (authModal === 'login' || authModal === 'register') ? (
    <>
      <span
        className={cx(classes.titleOption, { [classes.selected]: authModal === 'login' })}
        onClick={() => dispatch(setAuthModal('login'))}
      >
        {AUTH_MODAL_TITLE['login']}
      </span>
      <span> / </span>
      <span
        className={cx(classes.titleOption, { [classes.selected]: authModal === 'register' })}
        onClick={() => dispatch(setAuthModal('register'))}
      >
        {AUTH_MODAL_TITLE['register']}
      </span>
    </>
  ) : AUTH_MODAL_TITLE[authModal];

  const showErrorMessage = (error, defaultCode) => {
    const errorCode = error?.response?.data?.detail;
    const errorMessage = (errorCode && ERRORS.AUTH[errorCode])
      ? ERRORS.AUTH[errorCode]
      : ERRORS.AUTH[defaultCode];
    setError(errorMessage);
  };

  const showConfirmationMessage = code => {
    setError(null);
    setConfirmation(MESSAGES.AUTH[code]);
  };

  const handleLogin = async e => {
    e.preventDefault();
    setSending(true);

    try {
      await dispatch(loginUser(authData));
      dispatch(setAuthModal(null));
    } catch (e) {
      setSending(false);
      showErrorMessage(e, 'default-login');
    }
  };

  const handleRegister = async e => {
    e.preventDefault();

    if (authData.password !== authData.password2) {
      setError(ERRORS.AUTH['passwords-not-same']);
      return; 
    }
    setSending(true);

    const { password2, ...registrationData } = authData;
    try {
      await authService.register(registrationData);
      setSending(false);
      showConfirmationMessage('register-confirmation');
    } catch (e) {
      setSending(false);
      showErrorMessage(e, 'default-register');
    }
  };

  const handlePasswordRecovery = async e => {
    e.preventDefault();
    setSending(true);

    try {
      await authService.recoverPassword(authData);
      setSending(false);
      showConfirmationMessage('recovery-confirmation');
    } catch (e) {
      setSending(false);
      showErrorMessage(e, 'default-recovery');
    }
  };

  const handlePasswordReset = async e => {
    e.preventDefault();

    if (authData.password !== authData.password2) {
      setError(ERRORS.AUTH['passwords-not-same']);
      return; 
    }
    setSending(true);

    try {
      await dispatch(resetPassword({ ...authData, ...routeState }));
      setSending(false);
      showConfirmationMessage('reset-confirmation');
      setTimeout(() => dispatch(setAuthModal(null)), 2500);
    } catch (e) {
      setSending(false);
      showErrorMessage(e, 'default-reset');
    }
  };

  const onSubmit = {
    'login': handleLogin,
    'register': handleRegister,
    'password-recovery': handlePasswordRecovery,
    'password-reset': handlePasswordReset
  };

  return (
    <div>
      <div
        className={classes.background}
        onClick={() => dispatch(setAuthModal(null))}
      />
      <div className={classes.modal} >
        <h1 className={classes.title}>
          {renderModalTitle()}
        </h1>
        <div className={classes.error}>
          {error ? error : ''}
        </div>
        {confirmation ? (
          <>
            <div className={classes.confirmation}>{confirmation}</div>
            <div className={classes.buttonContainer}>
              <Button size='small' center onClick={() => dispatch(setAuthModal(null))}>
                Aceptar
              </Button>
            </div>
          </>
        ) : (
          <form onSubmit={onSubmit[authModal]}>
            {AUTH_FORM_FIELDS[authModal].map(({ key, label, config }) => (
              <div key={key} className={classes.formField}>
                <label className={classes.fieldLabel}>
                  {label}
                </label>
                <input
                  className={classes.input}
                  {...config}
                  value={authData[key] || ''}
                  onChange={e => setAuthData(state => ({
                    ...state, [key]: e.target.value
                  }))}
                />
              </div>
            ))}
            {(authModal === 'login' || authModal === 'password-recovery') && (
              <button
                type='button'
                className={classes.link}
                onClick={() => dispatch(setAuthModal(
                  (authModal === 'login') ? 'password-recovery' : 'login'
                ))}
              >
                {AUTH_FORM_LINK[authModal]}
              </button>
            )}
            <div className={classes.buttonContainer}>
              <Button type='submit' size='small' center sending={sending}>
                {AUTH_BUTTON_LABEL[authModal]}
              </Button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}

export default AuthModal;