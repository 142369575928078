import React from 'react';
import classes from './FieldCommon.module.scss';

function TextAreaField({ label, value, onChange, ...otherProps }) {
  return (
    <div className={classes.container}>
      <label className={classes.label}>{label}</label>
      <textarea
        className={classes.input}
        rows={5}
        value={value || ''}
        onChange={onChange}
        {...otherProps}
      />
    </div>
  );
}

export default TextAreaField;