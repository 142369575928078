import React from 'react';
import cx from 'classnames';
import classes from './Menu.module.scss';
import useOutsideClick from 'features/common/hooks/useOutsideClick';

function Menu({
  children,
  type = 'bottom',
  open = false,
  width = null,
  close = () => {}
}) {
  const ref = useOutsideClick({ active: open, handler: close });

  return (
    <div
      className={cx(classes.menuContainer, classes[type], { [classes.open]: open })}
      ref={ref}
    >
      <div className={classes.triangle} />
      <div className={classes.menu} style={{ width }}>
        <ul onClick={close}>
          {children}
        </ul>
      </div>
    </div>
  );
}

export default Menu;