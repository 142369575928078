import { useCallback } from 'react';

function useFeatureEditHandlers(setData, saveFeature) {
  const handleFieldChange = useCallback(e => {
    setData(state => ({ ...state, [e.target.name]: e.target.value }));
  }, [setData]);

  const handleProvinceChange = useCallback(e => {
    setData(state => ({ ...state, provinciaId: e.target.value, municipioId: '' }));
  }, [setData]);

  const handleMunicipalityChange = useCallback(e => {
    setData(state => ({ ...state, municipioId: e.target.value }));
  }, [setData]);

  const handleImageChange = useCallback(e => {
    if (e.target.files.length) {
      setData(state => ({
        ...state,
        fotografia: {
          url: URL.createObjectURL(e.target.files[0]),
          file: e.target.files[0]
        }
      }));
    }
  }, [setData]);

  const handleImageDelete = useCallback(() => {
    setData(state => ({ ...state, fotografia: { url: null, file: null } }));
  }, [setData]);

  const handleLocationChange = useCallback(newLocation => {
    setData(state => ({ ...state, geom: { type: 'Point', coordinates: newLocation } }));
  }, [setData]);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    saveFeature();
  }, [saveFeature]);

  return {
    handleFieldChange,
    handleProvinceChange,
    handleMunicipalityChange,
    handleImageChange,
    handleImageDelete,
    handleLocationChange,
    handleSubmit
  };
}

export default useFeatureEditHandlers;