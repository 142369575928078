export const MAP_ID = 'sinpar';
export const SECONDS_BEFORE_TOKEN_EXP = 60;

export const RQ_STATUS = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error'
};

export const ERRORS = {
  AUTH: {
    'invalid-login': 'El usuario o la contraseña no son correctos',
    'unverified-user-email': 'La cuenta de correo electrónico asociada a la cuenta no ha sido aún verificada',
    'default-login': 'Lo sentimos, no ha sido posible iniciar sesión',
    'username-already-exists': 'El nombre de usuario ya está en uso',
    'email-already-exists': 'La cuenta de correo electrónico ya está en uso',
    'default-register': 'Lo sentimos, no ha sido posible crear la cuenta',
    'invalid-email': 'El email introducido no existe',
    'default-recovery': 'Lo sentimos, no ha sido posible enviar el correo de restablecimiento de contraseña',
    'passwords-not-same': 'Las contraseñas no son iguales',
    'invalid-password-reset': 'Lo sentimos, no ha sido posible cambiar la contraseña',
    'default-reset': 'Lo sentimos, no ha sido posible cambiar la contraseña'
  },
  MAP: {
    'feature-error': 'No ha sido posible cargar los datos',
    'feature-add-error': 'No ha sido posible crear el elemento',
    'feature-update-error': 'No ha sido posible guardar los datos',
    'feature-delete-error': 'No ha sido posible eliminar el elemento',
    'feature-no-location': 'Es necesario añadir la ubicación del elemento para crearlo'
  }
};

export const MESSAGES = {
  AUTH: {
    'register-confirmation': 'La cuenta ha sido creada con éxito. Para poder comenzar a usarla, por favor haz clic en el enlace que aparece en el correo electrónico de verificación que te acabamos de enviar.',
    'recovery-confirmation': 'Te hemos enviado un correo electrónico con las instrucciones para restablecer la contraseña.',
    'reset-confirmation': 'La contraseña ha sido modificada con éxito.'
  },
  MAP: {
    'delete-feature-confirm': '¿Quieres eliminar el elemento?'
  }
};