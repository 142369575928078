import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectFullSelectedFeature, selectFeatureStatus, selectSelectedFeatureData,
  selectIsEditingFeature
} from '../mapSlice';
import { selectIsAdmin } from 'features/auth/authSlice';
// import classes from './Feature.module.scss';
import FeatureView from './FeatureView/FeatureView';
import FeatureEdit from './FeatureEdit/FeatureEdit';
import FeatureLayerSelect from './FeatureLayerSelect/FeatureLayerSelect';

function Feature(props) {
  const selectedFeature = useSelector(selectFullSelectedFeature);
  const featureStatus = useSelector(selectFeatureStatus);
  const featureData = useSelector(selectSelectedFeatureData);
  const isEditingFeature = useSelector(selectIsEditingFeature);
  const isAdminUser = useSelector(selectIsAdmin);

  if (selectedFeature) {
    if (selectedFeature.isNew) {
      return selectedFeature.layer ? (
        <FeatureEdit
          selectedFeature={selectedFeature}
          featureStatus={featureStatus}
          featureData={featureData}
        />
      ) : <FeatureLayerSelect />;
    } else {
      const canEditData = isAdminUser || featureData?.isOwner;
      return (isEditingFeature && canEditData) ? (
        <FeatureEdit
          selectedFeature={selectedFeature}
          featureStatus={featureStatus}
          featureData={featureData}
        />
      ) : (
        <FeatureView
          selectedFeature={selectedFeature}
          featureStatus={featureStatus}
          featureData={featureData}
          canEditData={canEditData}
        />
      );
    }
  }
  
  return null;
}

export default Feature;