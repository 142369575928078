import React from 'react';
import { TIPOLOGIA_BIEN_INMATERIAL, PERIODO_HISTORICO, NIVEL_PROTECCION } from 'config/layers';
import TextField from 'features/common/ui/Field/TextField';
import ImageField from 'features/common/ui/Field/ImageField';

function BienInmaterialView({ data }) {
  return (
    <div>
      <TextField label='Nombre' value={data.nombre} />
      <TextField label='Tipología' value={TIPOLOGIA_BIEN_INMATERIAL[data.tipologia]} />
      <TextField label='Provincia' value={data.provincia.name} />
      <TextField label='Municipio' value={data.municipio.name} />
      <TextField label='Período histórico' value={PERIODO_HISTORICO[data.periodoHistorico]} />
      <TextField label='Carácter' value={data.caracter} />
      <TextField label='Tipología específica' value={data.tipologiaEspecifica} />
      <TextField label='Actividad' value={data.actividad} />
      <TextField label='Nivel de protección' value={NIVEL_PROTECCION[data.proteccion]} />
      <ImageField label='Fotografía' value={data.fotografia} />
    </div>
  );
}

export default BienInmaterialView;