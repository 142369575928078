import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom';
import { setAuthModal } from './authSlice';
import authService from './authService';
import Spinner from 'features/common/ui/Spinner/Spinner';
import NotFound from 'features/common/routing/NotFound';

const verify_function = {
  'register': authService.verifyRegistration,
  'password-reset': authService.verifyPasswordReset
};

const onSuccess = { 'register': 'login', 'password-reset': 'password-reset' };

function Auth({ verifyType }) {
  const { uuid, token } = useParams();
  const [notFound, setNotFound] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    async function verifyLink() {
      try {
        const routeState = (verifyType === 'password-reset') ? { uuid, token } : {};
        await verify_function[verifyType]({ uuid, token });
        dispatch(setAuthModal(onSuccess[verifyType]));
        navigate('/', { state: routeState });
      } catch (error) {
        setNotFound(true);
      }
    }

    verifyLink();
  }, [verifyType, uuid, token, dispatch, navigate]);

  return notFound ? <NotFound /> : <Spinner />;
}

export default Auth;