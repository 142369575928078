import React from 'react';
import classes from './FeatureHeader.module.scss';

function FeatureHeader({ title, loading, children }) {
  return (
    <div className={classes.header}>
      <h3 className={classes.title}>{title}</h3>
      <div className={classes.actions}>
        {loading ? <div className={classes.spinner} /> : children}
      </div>
    </div>
  );
}

export default FeatureHeader;