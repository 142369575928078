import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect'
import mapService from './mapService';
import { RQ_STATUS, ERRORS } from 'config/main';
import { isSameFeature } from 'utils/feature';

const initialState = {
  isMobile: false,
  status: 'loading',
  fullMap: null,
  basemap: null,
  styleLoaded: false,
  sourceEvent: null,
  layersVisibility: {},
  selectedFeature: null,
  isEditingFeature: false,
  featureData: {},
  featureStatus: RQ_STATUS.IDLE,
  locationEditor: { active: false, location: null },
  provinces: null,
  mobilePanel: null,
  notification: null,
  confirmModal: null
};

/**************************
 * Map Slice
 **************************/
export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    loadFullMap: (state, action) => {
      state.status = 'loaded';
      state.fullMap = action.payload;
      // Initialize basemap
      state.basemap = state.fullMap.config.basemaps[0];
      // Flatten map layers and initialize layersVisibility
      const layersList = flattenLayersRecursive(state.fullMap.layers);
      for (const layer of layersList) {
        state.layersVisibility[layer.id] = layer.visible;
      }
    },
    deleteMap: (state) => initialState,
    setBasemap: (state, action) => {
      state.basemap = action.payload;
    },
    setStyleLoaded: (state, action) => {
      state.styleLoaded = action.payload;
    },
    setSourceEvent: (state, action) => {
      state.sourceEvent = action.payload;
    },
    setLayersVisibility: (state, action) => {
      state.layersVisibility = action.payload;
    },
    toggleLayerVisibility: (state, action) => {
      const layerId = action.payload;
      state.layersVisibility[layerId] = !state.layersVisibility[layerId];
    },
    setSelectedFeature: (state, action) => {
      state.selectedFeature = action.payload;
      if (state.isMobile) {
        state.mobilePanel = null;
      }
    },
    addNewFeature: (state) => {
      state.selectedFeature = { id: null, isNew: true, source: null, layer: null };
    },
    setNewFeatureLayer: (state, action) => {
      state.selectedFeature.layer = action.payload;
      state.selectedFeature.source = action.payload;
    },
    setIsEditingFeature: (state, action) => {
      state.isEditingFeature = action.payload;
    },
    addFeatureData: (state, action) => {
      const { sourceId, data } = action.payload;
      state.featureData[sourceId] ??= {};
      state.featureData[sourceId][data.id] = data;
      state.featureStatus = RQ_STATUS.SUCCESS;
    },
    deleteFeatureData: (state, action) => {
      const { sourceId, featureId } = action.payload;
      delete state.featureData[sourceId][featureId];
      state.selectedFeature = null;
      state.featureStatus = RQ_STATUS.SUCCESS;
    },
    resetFeatureData: (state) => {
      state.featureData = {};
      state.featureStatus = RQ_STATUS.IDLE;
      state.selectedFeature = null;
    },
    setFeatureStatus: (state, action) => {
      state.featureStatus = action.payload;
    },
    activateLocationEditor: (state) => {
      state.locationEditor = { active: true, location: null };
    },
    setNewLocation: (state, action) => {
      state.locationEditor = { active: false, location: action.payload };
    },
    resetLocationEditor: (state) => {
      state.locationEditor = { active: false, location: null };
    },
    addProvinces: (state, action) => {
      state.provinces = action.payload;
    },
    addMunicipalities: (state, action) => {
      const { provId, municipalities } = action.payload;
      state.provinces.data[provId].municipalities = municipalities;
    },
    setMobilePanel: (state, action) => {
      state.mobilePanel = action.payload;
    },
    toggleMobilePanel: (state, action) => {
      state.mobilePanel = (state.mobilePanel === action.payload)
        ? null
        : action.payload;
      state.selectedFeature = null;
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setConfirmModal: (state, action) => {
      state.confirmModal = action.payload;
    }
  }
});

export default mapSlice.reducer;
export const {
  setIsMobile, setStatus, loadFullMap, deleteMap, setBasemap, setStyleLoaded,
  setSourceEvent, setLayersVisibility, toggleLayerVisibility,
  setSelectedFeature, addNewFeature, setNewFeatureLayer, setIsEditingFeature,
  addFeatureData, deleteFeatureData, resetFeatureData, setFeatureStatus,
  activateLocationEditor, setNewLocation, resetLocationEditor,
  addProvinces, addMunicipalities, setNotification, setMobilePanel,
  toggleMobilePanel, setConfirmModal
} = mapSlice.actions;


/**************************
 * Selector Functions
 **************************/
export const selectIsMobile = state => state.map.isMobile;
export const selectMapStatus = state => state.map.status;
export const selectFullMap = state => state.map.fullMap;
export const selectLayers = state => state.map.fullMap.layers;
// Memoized selector
export const selectLayersList = createSelector(selectLayers, layers => (
  flattenLayersRecursive(layers)
));
export const selectLayersDict = createSelector(selectLayersList, layersList => (
  layersList.reduce((dict, layer) => {
    dict[layer.id] = layer;
    return dict;
  }, {})
));
export const selectDataSources = state => state.map.fullMap.dataSources;
export const selectBasemaps = state => state.map.fullMap.config.basemaps;
export const selectCurrentBasemap = state => state.map.basemap;
export const selectStyleLoaded = state => state.map.styleLoaded;
export const selectSourceEvent = state => state.map.sourceEvent;
export const selectLayersVisibility = state => state.map.layersVisibility;
export const selectSelectedFeature = state => state.map.selectedFeature;
export const selectFullSelectedFeature = createSelector(
  [selectSelectedFeature, selectLayersDict, selectDataSources],
  (selectedFeature, layersDict, dataSources) => selectedFeature ? ({
    ...selectedFeature,
    layer: layersDict[selectedFeature.layer] || null,
    source: dataSources[selectedFeature.source]  || null
  }) : null
);
export const selectIsEditingFeature = state => state.map.isEditingFeature;
export const selectFeatureDataById = (state, sourceId, featureId) => (
  state.map.featureData[sourceId]?.[featureId]
);
export const selectSelectedFeatureData = state => state.map.selectedFeature 
  ? state.map.featureData[state.map.selectedFeature.source]?.[state.map.selectedFeature.id]
  : null;
export const selectFeatureStatus = state => state.map.featureStatus;
export const selectLocationEditor = state => state.map.locationEditor;
export const selectLocationEditorActive = state => state.map.locationEditor.active;
export const selectProvinces = state => state.map.provinces?.list || null;
export const selectMunicipalities = (state, provId) => (state.map.provinces && provId)
  ? state.map.provinces.data[provId]?.municipalities || null
  : null;
export const selectMobilePanel = state => state.map.mobilePanel;
export const selectNotification = state => state.map.notification;
export const selectConfirmModal = state => state.map.confirmModal;


/**************************
 * Async Action Creators
 **************************/
export const fetchFullMap = () => async dispatch => {
  try {
    const fullMap = await mapService.getFullMap();
    dispatch(loadFullMap(fullMap));
  } catch(error) {
    dispatch(setStatus('error'));
  }
};

export const handleFeatureClick = (feature) => async (dispatch, getState) => {
  if (!feature) {
    dispatch(setSelectedFeature(null));
  } else {
    const state = getState();
    const selectedFeature = selectSelectedFeature(state);
    
    if (selectedFeature && isSameFeature(feature, selectedFeature)) {
      dispatch(setSelectedFeature(null));
    } else {
      dispatch(setSelectedFeature(feature));
      
      const dataSource = selectDataSources(state)[feature.source];
      if (
        dataSource?.type === 'dataset' &&
        !selectFeatureDataById(state, dataSource.id, feature.id)
      ) {
        dispatch(fetchFeature(dataSource, feature.id));
      }
    }
  }
};

export const fetchFeature = (dataSource, featureId) => async dispatch => {
  try {
    const data = await mapService.getFeature(dataSource.url, featureId);
    dispatch(addFeatureData({ sourceId: dataSource.id, data }));
  } catch (error) {
    dispatch(setFeatureStatus(RQ_STATUS.ERROR));
  }
};

export const addOrUpdateFeature = ({
  isUpdate = false, dataSource, featureId = null, featureData
}) => async (dispatch, getState) => {
  const { fotografia, ...featureJsonData } = featureData;
  dispatch(setFeatureStatus(RQ_STATUS.PENDING));
  const currentFeatureData = selectFeatureDataById(getState(), dataSource.id, featureId);
  
  try {
    // Add/update JSON data
    const data = isUpdate
      ? await mapService.updateFeature(dataSource.url, featureId, featureJsonData)
      : await mapService.addFeature(dataSource.url, featureJsonData);

    // If there is an image upload/update, handle it with a separate request (form-data)
    if (fotografia.file) {
      const formData = new FormData();
      formData.append('file', fotografia.file);
      const url = await mapService.addFeatureImage(
        dataSource.url, featureId || data.id, formData
      );
      data.fotografia = url;
    } else if (isUpdate && currentFeatureData.fotografia && !fotografia.url) {
      await mapService.deleteFeatureImage(dataSource.url, featureId);
      data.fotografia = null;
    }

    dispatch(addFeatureData({ sourceId: dataSource.id, data }));
    
    // If 'tipologia' or 'geom' changes we need to reset the map source
    // to update the map feature (icon or position)
    if (
      !isUpdate ||
      currentFeatureData.tipologia !== featureJsonData.tipologia ||
      currentFeatureData.geom.coordinates !== featureJsonData.geom.coordinates
    ) {
      dispatch(setSourceEvent({ type: 'reset', payload: dataSource }));
    }
    return data.id;
  } catch (error) {
    dispatch(setFeatureStatus(RQ_STATUS.ERROR));
    dispatch(setNotification({
      message: ERRORS.MAP[isUpdate ? 'feature-update-error' : 'feature-add-error'],
      isError: true
    }));
    return null;
  }
};

export const deleteFeature = ({ dataSource, featureId }) => async dispatch => {
  dispatch(setFeatureStatus(RQ_STATUS.PENDING));
  try {
    await mapService.deleteFeature(dataSource.url, featureId);
    dispatch(deleteFeatureData({ sourceId: dataSource.id, featureId }));
    // Reset the map source to remove the feature from the map
    dispatch(setSourceEvent({ type: 'reset', payload: dataSource }));
  } catch (error) {
    dispatch(setFeatureStatus(RQ_STATUS.ERROR));
    dispatch(setNotification({
      message: ERRORS.MAP['feature-delete-error'],
      isError: true
    }));
  }
};

export const fetchProvinces = () => async dispatch => {
  try {
    let list = await mapService.getProvinces();
    list = list.map(p => ({ value: p.id, label: p.name }));
    const data = list.reduce((dict, prov) => {
      dict[prov.value] = { ...prov, municipalities: null };
      return dict;
    }, {});
    dispatch(addProvinces({ list, data }));
  } catch (error) {
    console.log('Error fetching provinces: ', error);
  }
};

export const fetchMunicipalities = (provId) => async dispatch => {
  try {
    let municipalities = await mapService.getMunicipalities(provId);
    municipalities = municipalities.map(m => ({ value: m.id, label: m.name }));
    dispatch(addMunicipalities({ provId, municipalities }));
  } catch (error) {
    console.log('Error fetching municipalities: ', error);
  }
};

export const setBasemapStyle = ({ style, map, fullMap, selectedRef }) => (
  async (dispatch, getState) => {
    const state = getState();
    const styleId = style.replace('mapbox://styles/', '');
    const newStyle = await mapService.getMapboxStyle(styleId, fullMap.mapboxToken);
    
    const currentStyle = map.current.getStyle();
    if (currentStyle) {
      const dataSources = selectDataSources(state);

      // Ensure any sources from the current style are copied across to the new style
      newStyle.sources = { ...currentStyle.sources, ...newStyle.sources };

      // Retain only app layers
      const appLayers = currentStyle.layers.filter(l => dataSources[l.source]);

      newStyle.layers = [...newStyle.layers, ...appLayers];
    }

    map.current.setStyle(newStyle);

    // Load map icons if there are
    if (fullMap.config.icons) {
      for (const [id, url] of Object.entries(fullMap.config.icons)) {
        map.current.loadImage(url, (error, image) => {
          if (error) throw error;
          map.current.addImage(id, image);
        });
      }
    }
    
    // If there is a selected feature, add again its feature state on idle
    if (selectedRef.current) {
      map.current.once('idle', () => {
        map.current.setFeatureState({
          id: selectedRef.current.id,
          source: selectedRef.current.source,
          sourceLayer: selectedRef.current.sourceLayer
        }, { highlight: true, select: true });
      });
    }
  }
);


/**************************
 * Utility Functions
 **************************/
const flattenLayersRecursive = lyElems => {
  const flat = [];
  for (const lyElem of lyElems) {
    if (lyElem.type === 'layer') {
      flat.push(lyElem);
    } else {
      flat.push(...flattenLayersRecursive(lyElem.children));
    }
  }
  return flat;
};
