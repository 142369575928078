import React from 'react';
import {
  TIPOLOGIA_BIEN_INMATERIAL_LIST, PERIODO_HISTORICO_LIST, NIVEL_PROTECCION_LIST
} from 'config/layers';
import useProvincesMunicipalities from '../../hooks/useProvincesMunicipalities';
import useFeatureEditHandlers from './useFeatureEditHandlers';
import LocationEditor from './LocationEditor';
import InputField from 'features/common/ui/Field/InputField';
import SelectField from 'features/common/ui/Field/SelectField';
import ImageInputField from 'features/common/ui/Field/ImageInputField';

function BienInmaterialEdit({ data, setData, saveFeature }) {
  const {
    handleFieldChange, handleProvinceChange, handleMunicipalityChange,
    handleImageChange, handleImageDelete, handleLocationChange, handleSubmit
  } = useFeatureEditHandlers(setData, saveFeature);

  const { provinces, municipalities } = useProvincesMunicipalities(
    data.provinciaId, data.municipioId
  );

  return (
    <form id='feature-form' onSubmit={handleSubmit}>
      <LocationEditor value={data.geom} onChange={handleLocationChange} />
      <InputField
        name='nombre'
        label='Nombre'
        value={data.nombre}
        onChange={handleFieldChange}
        required
      />
      <SelectField
        name='tipologia'
        label='Tipología'
        value={data.tipologia}
        options={TIPOLOGIA_BIEN_INMATERIAL_LIST}
        blankOption='Selecciona una tipología'
        onChange={handleFieldChange}
        required
      />
      <SelectField
        name='provincia'
        label='Provincia'
        value={data.provinciaId}
        options={provinces || []}
        blankOption='Selecciona una provincia'
        onChange={handleProvinceChange}
        required
      />
      <SelectField
        name='municipio'
        label='Municipio'
        value={data.municipioId}
        options={municipalities || []}
        blankOption='Selecciona un municipio'
        onChange={handleMunicipalityChange}
        required
      />
      <SelectField
        name='periodoHistorico'
        label='Período histórico'
        value={data.periodoHistorico}
        options={PERIODO_HISTORICO_LIST}
        blankOption='Selecciona un período histórico'
        onChange={handleFieldChange}
      />
      <InputField
        name='caracter'
        label='Carácter'
        value={data.caracter}
        onChange={handleFieldChange}
      />
      <InputField
        name='tipologiaEspecifica'
        label='Tipología específica'
        value={data.tipologiaEspecifica}
        onChange={handleFieldChange}
      />
      <InputField
        name='actividad'
        label='Actividad'
        value={data.actividad}
        onChange={handleFieldChange}
      />
      <SelectField
        name='proteccion'
        label='Nivel de protección'
        value={data.proteccion}
        options={NIVEL_PROTECCION_LIST}
        blankOption='Selecciona un nivel de protección'
        onChange={handleFieldChange}
      />
      <ImageInputField
        label='Fotografía'
        value={data.fotografia.url}
        onChange={handleImageChange}
        onDelete={handleImageDelete}
      />
      <input type='submit' style={{ display: 'none' }}/>
    </form>
  );
}

export default BienInmaterialEdit;