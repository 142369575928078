import { useEffect } from 'react';
import {  useDispatch } from 'react-redux'
import { setIsMobile } from '../mapSlice';
import cssVariables from 'assets/css/variables.scss';

const mobileMediaQuery = `(max-width: ${cssVariables.mobileMaxWidth})`;

function useMediaQueries() {
  const dispatch = useDispatch();

  
  useEffect(() => {
    const handleMediaChange = e => dispatch(setIsMobile(e.matches));
    
    dispatch(setIsMobile(window.matchMedia(mobileMediaQuery).matches));
    window.matchMedia(mobileMediaQuery).addEventListener('change', handleMediaChange);

    return () => {
      window.matchMedia(mobileMediaQuery).removeEventListener('change', handleMediaChange);
    };
  }, [dispatch]);
}

export default useMediaQueries;