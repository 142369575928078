import React from 'react';
import classes from './LinkField.module.scss';

function LinkField({ label, value }) {
  return (
    <div className={classes.container}>
      <div className={classes.label}>{label}</div>
      {value ? (
        <a className={classes.link} href={value} target='_blank' rel='noreferrer'>
          {value || '-'}
        </a>
      ) : <p className={classes.value}>-</p>}
    </div>
  );
}

export default LinkField;