import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classes from './MapControls.module.scss';
import {
  selectBasemaps, selectCurrentBasemap, setBasemap, addNewFeature
} from '../mapSlice';
import { selectAuthenticated, setAuthModal } from 'features/auth/authSlice';
import Menu from 'features/common/ui/Menu/Menu';
import MenuItem from 'features/common/ui/Menu/MenuItem';
import basemapIcon from  'assets/svg/basemap.svg';
import collaborateIcon from  'assets/svg/collaborate.svg';

function MapControls(props) {
  const basemaps = useSelector(selectBasemaps);
  const currentBasemap = useSelector(selectCurrentBasemap);
  const authenticated = useSelector(selectAuthenticated);
  const [menuOpen, setMenuOpen] = useState(false);
  const dispatch = useDispatch();

  function handleCollaborateClick() {
    if (authenticated) {
      dispatch(addNewFeature());
    } else {
      dispatch(setAuthModal('login'));
    }
  }

	return (
    <>
      <div className={classes.basemapControl}>
        <button
          className={classes.controlButton}
          onClick={() => setMenuOpen(state => !state)}
        >
          <img className={classes.controlIcon} src={basemapIcon} alt='basemap' />
        </button>
        <Menu
          type='left'
          open={menuOpen}
          close={() => setMenuOpen(false)}
        >
          {basemaps.map(bm => (
            <MenuItem
              key={bm.name}
              selected={bm === currentBasemap}
              onClick={() => dispatch(setBasemap(bm))}
            >
              {bm.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <button
        className={classes.collaborateButton}
        onClick={handleCollaborateClick}
      >
        <img className={classes.collaborateIcon} src={collaborateIcon} alt='collaborate' />
      </button>
    </>
  );
}

export default MapControls;