import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import {
  selectLayers, selectDataSources, selectLayersVisibility, toggleLayerVisibility
} from '../mapSlice';
import classes from './Layers.module.scss';
import Legend from './Legend';
import { ReactComponent as ArrowIcon } from 'assets/svg/arrow.svg';
import pointIcon from  'assets/svg/point.svg';
import lineIcon from  'assets/svg/line.svg';
import polygonIcon from  'assets/svg/polygon.svg';
import rasterIcon from  'assets/svg/raster.svg';
import groupIcon from  'assets/svg/group.svg';

const LAYER_ICONS = {
  'POINT': pointIcon,
  'LINESTRING': lineIcon,
  'POLYGON': polygonIcon,
  'GEOMETRY': polygonIcon,
  'MULTIPOINT': pointIcon,
  'MULTILINESTRING': lineIcon,
  'MULTIPOLYGON': polygonIcon,
  'GEOMETRYCOLLECTION': polygonIcon,
};

function Layers(props) {
  const layers = useSelector(selectLayers);
  const dataSources = useSelector(selectDataSources);
  const layersVisibility = useSelector(selectLayersVisibility);
  const dispatch = useDispatch();

  const [expandedElems, setExpandedElems] = useState({});

  const toggleExpansion = (e, id) => {
    e.stopPropagation();
    setExpandedElems(state => ({ ...state, [id]: !state[id] }))
  };

  const renderLayer = ({ id, source, name, borderColor, borderGradient, legend }) => {
    let isExpanded = legend && expandedElems[id];
    return (
      <li
        key={id}
        className={cx(classes.layer, { [classes.expanded]: isExpanded })}
        onClick={() => dispatch(toggleLayerVisibility(id))}
      >
        <div
          className={classes.layerBorder}
          style={{
            backgroundColor: layersVisibility[id] && borderColor,
            backgroundImage: layersVisibility[id] && borderGradient
              && `linear-gradient(${borderGradient})`
          }}
        />
        <div className={classes.layerHeader}>
          <img
            className={classes.layerHeaderIcon}
            src={getLayerIcon(dataSources[source])}
            alt='layer-icon'
          />
          <span className={classes.layerHeaderName}>{name}</span>
          {legend && (
            <button
              className={classes.layerArrowButton}
              onClick={e => toggleExpansion(e, id)}
            >
              <ArrowIcon className={classes.layerArrowIcon} />
            </button>
          )}
        </div>
        {isExpanded && <Legend {...legend} />}
      </li>
    );
  };

  const renderLayerElement = (lyElem) => {
    if (lyElem.type === 'layer') {
      return renderLayer(lyElem);
    } else if (lyElem.type === 'section') {
      return (
        <div key={lyElem.name} className={classes.section}>
          <h2>{lyElem.name}</h2>
          <ul>
            {lyElem.children.map(child => renderLayerElement(child))}
          </ul>
        </div>
      );
    } else if (lyElem.type === 'group') {
      let isExpanded = expandedElems[lyElem.id];
      return (
        <div
          key={lyElem.name}
          className={cx(classes.group, { [classes.expanded]: isExpanded })}
        >
          <div
            className={classes.groupHeader}
            onClick={e => toggleExpansion(e, lyElem.id)}
          >
            <img
              className={classes.groupHeaderIcon}
              src={groupIcon}
              alt='layer-group'
            />
            <span className={classes.groupHeaderName}>
              {lyElem.name}
            </span>
          </div>
          {isExpanded && (
            <ul className={classes.groupLayers}>
              {lyElem.children.map(child => renderLayer(child))}
            </ul>
          )}
        </div>
      );
    }
  };

  return (
    <div className={classes.layersLayout}>
      {layers.map(lyElem => renderLayerElement(lyElem))}
    </div>
  );
}

export default Layers;

const getLayerIcon = (dataSource) => (
  (dataSource.type === 'raster' || dataSource.type === 'wms') 
    ? rasterIcon
    : LAYER_ICONS[dataSource.geometry]
);