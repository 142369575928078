import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import {
  selectMobilePanel, selectSelectedFeature, toggleMobilePanel, addNewFeature
} from '../mapSlice';
import { selectAuthenticated, setAuthModal } from 'features/auth/authSlice';
import classes from './MobilePanel.module.scss';
import UserMenu from 'features/auth/UserMenu';
import Feature from '../Feature/Feature';
import Layers from '../Layers/Layers';
import MapControls from '../MapControls/MapControls';
import logo from 'assets/svg/sinpar-logo.svg';
import layersIcon from 'assets/svg/layers.svg';
import collaborateIcon from  'assets/svg/collaborate.svg';

function MobilePanel(props) {
  const mobilePanel = useSelector(selectMobilePanel);
  const authenticated = useSelector(selectAuthenticated);
  const selectedFeature = useSelector(selectSelectedFeature);
  const dispatch = useDispatch();

  function handleCollaborateClick() {
    if (authenticated) {
      dispatch(addNewFeature());
    } else {
      dispatch(setAuthModal('login'));
    }
  }

  const isPanelOpen = selectedFeature || mobilePanel === 'layers';

  return (
    <>
      <div className={cx(classes.navbar, { [classes.open]: isPanelOpen })}>
        <div className={classes.separator} />
        <div className={classes.navItems}>
          <img className={classes.logo} src={logo} alt='logo' />
          <UserMenu menuType='top' />
          <button
            className={classes.navButton}
            onClick={() => dispatch(toggleMobilePanel('layers'))}
          >
            <img className={classes.layersIcon} src={layersIcon} alt='layers' />
          </button>
          <button className={classes.navButton} onClick={handleCollaborateClick}>
            <img className={classes.collaborateIcon} src={collaborateIcon} alt='collaborate' />
          </button>
        </div>
      </div>
      <div className={cx(classes.panel, { [classes.open]: isPanelOpen })}>
        {selectedFeature
          ? <Feature />
          : (mobilePanel === 'layers')
            ? <Layers />
            : null
        }
      </div>
      <MapControls />
    </>
  );
}

export default MobilePanel;