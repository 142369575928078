import React from 'react';
import classes from './Legend.module.scss';

function Legend({ type, items, image }) {
  if (type === 'icons') {
    return (
      <div className={classes.legend}>
        <ul>
          {items.map((item, idx) => (
            <li key={idx} className={classes.item}>
              <img className={classes.icon} src={item.icon} alt='legend-icon' />
              {/* <div className={classes.colorBox} style={{ backgroundColor: row.color }}/> */}
              <span className={classes.label}>{item.label}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  } else if (type === 'image') {
    return (
      <div className={classes.legend}>
        <img className={classes.legendImage} srcSet={image + ' 1.25x'} alt='legend' />
      </div>
    );
  } else {
    return null;
  }
}

export default Legend;