import React from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { setNewFeatureLayer } from '../../mapSlice';
import classes from './FeatureLayerSelect.module.scss';
import FeatureLayout from '../FeatureLayout/FeatureLayout';

function FeatureLayerSelect(props) {
  const dispatch = useDispatch();
  
  return (
    <FeatureLayout title='¿Qué tipo de elemento es?'>
      <h3 className={classes.header}>Patrimonio</h3>
      <div className={classes.buttonsRow}>
        <button
          className={cx(classes.button, classes.yellow)}
          onClick={() => dispatch(setNewFeatureLayer('bien-inmueble'))}
        >
          Bien inmueble
        </button>
        <button
          className={cx(classes.button, classes.green)}
          onClick={() => dispatch(setNewFeatureLayer('bien-inmaterial'))}
        >
          Bien inmaterial
        </button>
      </div>
      <h3 className={classes.header}>Agente</h3>
      <div className={classes.buttonsRow}>
        <button
          className={cx(classes.button, classes.blue)}
          onClick={() => dispatch(setNewFeatureLayer('agente-patrimonial'))}
        >
          Agente
        </button>
      </div>
    </FeatureLayout>
  );
}

export default FeatureLayerSelect;