import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MapContainer from 'features/map/MapContainer/MapContainer';
import VerifyLink from 'features/auth/VerifyLink';
import NotFound from 'features/common/routing/NotFound';

function App() {
  return (
  	<Routes>
      <Route path="/" element={<MapContainer />} />
      <Route path="/" element={<MapContainer />} />
      <Route
        path='/register/:uuid/:token'
        element={<VerifyLink verifyType='register' />}
      />
      <Route
        path='/password-reset/:uuid/:token'
        element={<VerifyLink verifyType='password-reset' />}
      />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export default App;