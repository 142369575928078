import React, { useState, useLayoutEffect } from 'react';
import cx from 'classnames';
import classes from './ImageField.module.scss';
import noImage from 'assets/svg/no-image.svg';

function ImageField({ label, value, height = '150px' }) {
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);

  useLayoutEffect(() => {
    setShow(false);
    setError(false);
  }, [value]);

  return (
    <div className={classes.container}>
      <div className={classes.label}>{label}</div>
        <a href={value} target='_blank' rel='noreferrer'>
          <img
            className={cx(classes.image, {
              [classes.show]: show,
              [classes.noImage]: !value
            })}
            style={{ height }}
            src={(value && !error) ? value : noImage}
            onLoad={() => setShow(true)}
            onError={e => setError(true)}
            alt={label}
          />
        </a>
    </div>
  );
}

export default ImageField;