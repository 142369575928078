import React from 'react';
import classes from './BasicLayout.module.scss';
import backgroundImg from 'assets/img/background.jpg';

function BasicLayout({ children }) {
  return (
  	<div
  		className={classes.layoutContainer}
  		style={{ backgroundImage: `url(${backgroundImg})`}}
  	>
	  	{children}
  	</div>
  );
}

export default BasicLayout;