import React from 'react';
import { Link } from 'react-router-dom';
import classes from './NotFound.module.scss';
import BasicLayout from 'features/common/layout/BasicLayout';
// import logo from 'assets/svg/logo-blue.svg';

function NotFound(props) {
  return (
  	<BasicLayout>
  		<div className={classes.container}>
  			{/* <img className={classes.logo} src={logo} alt='logo' /> */}
  			<h1 className={classes.title}>Error 404</h1>
  			<p className={classes.text}>
  				Lo sentimos, la página que buscas no existe
        </p>
  			<Link className={classes.link} to='/'>
  				Volver a la página principal
  			</Link>
  		</div>
  	</BasicLayout>
  );
}

export default NotFound;