import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedFeature, selectIsMobile } from '../mapSlice';
import { isSameFeature } from 'utils/feature';
import cssVariables from 'assets/css/variables.scss';

function useFeatures({ map, hoveredRef, selectedRef }) {
	const selectedFeature = useSelector(selectSelectedFeature);
  const isMobile = useSelector(selectIsMobile);
  const centerMapOnFeature = useRef(null);

  useEffect(() => {
    if (isMobile) {
      const bottom = parseFloat(cssVariables.mobileNavbarHeight) +
        window.innerHeight * parseFloat(cssVariables.mobilePanelHeight) / 100;
      centerMapOnFeature.current = lngLat => map.current.easeTo({
        center: lngLat,
        duration: 500,
        padding: { bottom }
      });
    } else {
      centerMapOnFeature.current = lngLat => map.current.easeTo({
        center: lngLat,
        duration: 500
      });
    }
  }, [isMobile, map]);

  /**
   * Feature selected useEffect
   */
  useEffect(() => {
    if (selectedRef.current) {
      map.current.setFeatureState({
        id: selectedRef.current.id,
        source: selectedRef.current.source,
        sourceLayer: selectedRef.current.sourceLayer
      }, {
        select: false,
        highlight: (
          hoveredRef.current &&
          isSameFeature(hoveredRef.current, selectedRef.current)
        )
      });
      selectedRef.current = null;
    }
    
    if (selectedFeature && !selectedFeature.isNew) {
      map.current.setFeatureState({
        id: selectedFeature.id,
        source: selectedFeature.source,
        sourceLayer: selectedFeature.sourceLayer
      }, { highlight: true, select: true });
      
      if (selectedFeature.lngLat) {
        centerMapOnFeature.current(selectedFeature.lngLat);
      }
      selectedRef.current = selectedFeature;
    }
  }, [selectedFeature, selectedRef, hoveredRef, map]);
}

export default useFeatures;