import { useEffect, useRef, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import {
	selectLayersList, selectDataSources, selectStyleLoaded, selectLayersVisibility,
  selectSourceEvent, selectLocationEditor, handleFeatureClick, setNewLocation
} from '../mapSlice';
import { isSameFeature, getMapboxLayersWithEvents } from 'utils/feature';
import markerImage from 'assets/svg/location-marker.svg';

const SOURCE_PARSERS = {
  'dataset': ds => ({ type: 'geojson', data: ds.url + '/geojson', ...ds.config }),
  'vector-tileset': ds => ({ type: 'vector', url: ds.url, ...ds.config }),
  'raster-tileset': ds => ({ type: 'raster', url: ds.url, ...ds.config }),
  'dataset-with-vector-tileset': ds => ({ type: 'vector', url: ds.url, ...ds.config }),
  'wms': ds => ({ type: 'raster', tiles: [ds.url], ...ds.config })
};

function useLayers({ map, hoveredRef, selectedRef }) {
	const layersList = useSelector(selectLayersList);
  const dataSources = useSelector(selectDataSources);
  const styleLoaded = useSelector(selectStyleLoaded);
	const layersVisibility = useSelector(selectLayersVisibility);
  const sourceEvent = useSelector(selectSourceEvent);
  const locationEditor = useSelector(selectLocationEditor);
  const marker = useRef(null);
  const isMobileTouchEvent = useRef(false);
	const dispatch = useDispatch();

  const mapboxLayersWithEvents = useMemo(() => (
    getMapboxLayersWithEvents(layersList)
  ), [layersList]);

  /**
   * Event handlers useCallbacks
   */
  const mouseMoveHandler = useCallback(e => {
    if (isMobileTouchEvent.current) {
      isMobileTouchEvent.current = false;
      return;
    }
    
    const feature = e.features[0];
    if (hoveredRef.current) {
      if (isSameFeature(feature, hoveredRef.current)) return;

      if (
        !selectedRef.current ||
        !isSameFeature(selectedRef.current, hoveredRef.current)
      ) {
        map.current.setFeatureState({
          id: hoveredRef.current.id,
          source: hoveredRef.current.source,
          sourceLayer: hoveredRef.current.sourceLayer
        }, { highlight: false });
      }
    } else {
      map.current.getCanvas().style.cursor = 'pointer';
    }

    map.current.setFeatureState({
      id: feature.id,
      source: feature.source,
      sourceLayer: feature.sourceLayer
    }, { highlight: true });

    hoveredRef.current = feature;
  }, [map, hoveredRef, selectedRef]);

  const mouseLeaveHandler = useCallback(() => {
    map.current.getCanvas().style.cursor = '';

    if (hoveredRef.current && (
        !selectedRef.current ||
        !isSameFeature(selectedRef.current, hoveredRef.current)
    )) {
      map.current.setFeatureState({
        id: hoveredRef.current.id,
        source: hoveredRef.current.source,
        sourceLayer: hoveredRef.current.sourceLayer
      }, { highlight: false });
    }

    hoveredRef.current = null;  
  }, [map, hoveredRef, selectedRef]);

  const clickHandler = useCallback(e => {
    e.originalEvent.preventDefault();
    const {
      id, properties, layer: mbLayer, source, sourceLayer
    } = e.features[0];

    dispatch(handleFeatureClick({
      id,
      properties,
      layer: mbLayer.id.substring(0, mbLayer.id.lastIndexOf(".")),
      source,
      sourceLayer,
      point: { ...e.point },
      lngLat: { ...e.lngLat }
    }));
  }, [dispatch]);

  const basemapClickHandler = useCallback(e => {
    if (!e.originalEvent.defaultPrevented) {
      dispatch(handleFeatureClick(null));
    }
  }, [dispatch]);

  const touchEndHandler = useCallback(() => {
    isMobileTouchEvent.current = true;
  }, []);

  const locationEditorClickHandler = useCallback(e => {
    map.current.getCanvas().style.cursor = '';
    marker.current.setLngLat(e.lngLat).addTo(map.current);
    dispatch(setNewLocation(e.lngLat.toArray()));
  }, [map, dispatch]);


  /**
   * Create marker useEffect
   */
  useEffect(() => {
    const el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundImage = `url(${markerImage})`;
    marker.current = new mapboxgl.Marker(el, { anchor: 'bottom' });
  }, []);


	/**
   * Add/update map event handlers useEffect
   */
	useEffect(() => {
    const mapRefCopy = map.current;
    if (locationEditor.active) {
      map.current.getCanvas().style.cursor = 'crosshair';
      map.current.once('click', locationEditorClickHandler);

      return () => {
        mapRefCopy.getCanvas().style.cursor = '';
        mapRefCopy.off('click', locationEditorClickHandler);        
      }
    } else {
      map.current.on('mousemove', mapboxLayersWithEvents, mouseMoveHandler);
      map.current.on('mouseleave', mapboxLayersWithEvents, mouseLeaveHandler);
      map.current.on('click', mapboxLayersWithEvents, clickHandler);
      map.current.on('click', basemapClickHandler);
      map.current.on('touchend', touchEndHandler);

      return () => {
        mapRefCopy.off('mousemove', mapboxLayersWithEvents, mouseMoveHandler);
        mapRefCopy.off('mouseleave', mapboxLayersWithEvents, mouseLeaveHandler);
        mapRefCopy.off('click', mapboxLayersWithEvents, clickHandler);
        mapRefCopy.off('click', basemapClickHandler);
        mapRefCopy.off('touchend', touchEndHandler);
      }
    }
	}, [
		mapboxLayersWithEvents, locationEditor.active, map, dispatch,
    mouseMoveHandler, mouseLeaveHandler, clickHandler,
    basemapClickHandler, touchEndHandler, locationEditorClickHandler
	]);


  /**
   * Remove existing markers on locationEditor reset useEffect
   */
  useEffect(() => {
    if (!locationEditor.active && !locationEditor.location) {
      marker.current.remove();
    }
  }, [locationEditor]);
	

  /**
   * Layers visibility updates useEffect
   */
	useEffect(() => {
	 	if (!map.current || !styleLoaded) return;

	 	// Show all visible layers (loading layers if necessary)
	 	for (const layer of layersList) {
   		const visibility = layersVisibility[layer.id] ? 'visible' : 'none';

      if (map.current.getLayer(layer.mbLayers[0].id)) {
        for (const mbLayer of layer.mbLayers) {
          if (map.current.getLayoutProperty(mbLayer.id, 'visibility') !== visibility) {
            if (visibility === 'visible') {
              map.current.moveLayer(mbLayer.id);
            }
            map.current.setLayoutProperty(mbLayer.id, 'visibility', visibility);
          }
        }
      }
      else if (visibility === 'visible') {
        const ds = dataSources[layer.source];
        if (!map.current.getSource(ds.id)) {
          map.current.addSource(
            ds.id,
            SOURCE_PARSERS[ds.type](ds)
          );
          for (const mbLayer of layer.mbLayers) {
            map.current.addLayer(mbLayer);
          }
        }
      }
		}
	}, [styleLoaded, layersVisibility, layersList, dataSources, map, dispatch]);


  /**
   * Source event useEffect
   */
  useEffect(() => {
    if (sourceEvent && sourceEvent.type === 'reset') {
      map.current
        .getSource(sourceEvent.payload.id)
        .setData(sourceEvent.payload.url + '/geojson');
    }
  }, [sourceEvent, map]);
}

export default useLayers;