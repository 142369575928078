import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { selectSelectedFeature } from '../mapSlice';
import classes from './RightPanel.module.scss';
import useShiftMapboxControls from '../hooks/useShiftMapboxControls';
import MapControls from '../MapControls/MapControls';
import Feature from '../Feature/Feature';

function RightPanel(props) {
  const selectedFeature = useSelector(selectSelectedFeature);

  // Custom hook to shift Mapbox Map Controls when RightPanel opens
  useShiftMapboxControls(selectedFeature);

	return (
    <div className={cx(classes.panelContainer, { [classes.open]: selectedFeature })}>
      <div className={classes.panel}>
        <Feature />
      </div>
      <MapControls />
    </div>
  );
}

export default RightPanel;