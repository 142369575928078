import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectConfirmModal, setConfirmModal, deleteFeature } from 'features/map/mapSlice';
import classes from './ConfirmModal.module.scss';
import Button from '../Button/Button';

const ACTIONS = {
  'deleteFeature': deleteFeature
};

function ConfirmModal(props) {
  const confirmModal = useSelector(selectConfirmModal);
  const dispatch = useDispatch();
  
  function handleBackgroundClick(e) {
    if (e.target === e.currentTarget) {
      dispatch(setConfirmModal(null));
    }
  }

  function handleOk() {
    const { confirmAction, confirmPayload } = confirmModal;
    dispatch(setConfirmModal(null));
    dispatch(ACTIONS[confirmAction](confirmPayload));
  }

  return confirmModal ? (
    <div className={classes.background} onClick={handleBackgroundClick}>
    	<div className={classes.modal}>
        <p className={classes.message}>{confirmModal.message}</p>
        <div className={classes.buttons}>
          <Button color='secondary'  onClick={() => dispatch(setConfirmModal(null))}>
            {confirmModal.cancelLabel || 'Cancelar'}
          </Button>
          <Button onClick={handleOk}>
            {confirmModal.okLabel || 'Aceptar'}
          </Button>
        </div>
      </div>
    </div>
  ) : null;
}

export default ConfirmModal;