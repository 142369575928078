import React from 'react';
import cx from 'classnames';
import classes from './MenuItem.module.scss';

function MenuItem({ children, header = false, selected = false, onClick = () => {} }) {
  return (
    <li
      className={cx({
        [classes.header]: header,
        [classes.menuItem]: !header,
        [classes.selected]: selected
      })}
      onClick={e => header ? e.stopPropagation() : onClick()}
    >
      {children}
    </li>
  );
}

export default MenuItem;