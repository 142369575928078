export function isSameFeature(f1, f2) {
  return f1.id === f2.id && f1.source === f2.source && f1.sourceLayer === f2.sourceLayer;
}

export function getMapboxLayersWithEvents(layersList) {
  const mapboxLayersWithEvents = [];
  for (const layer of layersList) {
    mapboxLayersWithEvents.push(
      ...layer.mbLayers.filter(l => l.events).map(l => l.id)
    );
  }
  return mapboxLayersWithEvents;
}

export const EDIT_FEATURE_PARSERS = {
  'bien-inmueble': f => ({
    nombre: f.nombre,
    tipologia: f.tipologia,
    provinciaId: f.provincia.id,
    municipioId: f.municipio.id,
    periodoHistorico: f.periodoHistorico,
    caracter: f.caracter,
    tipologiaEspecifica: f.tipologiaEspecifica,
    actividad: f.actividad,
    proteccion: f.proteccion,
    fotografia: {
      url: f.fotografia,
      file: null
    },
    geom: f.geom
  }),
  'bien-inmaterial': f => ({
    nombre: f.nombre,
    tipologia: f.tipologia,
    provinciaId: f.provincia.id,
    municipioId: f.municipio.id,
    periodoHistorico: f.periodoHistorico,
    caracter: f.caracter,
    tipologiaEspecifica: f.tipologiaEspecifica,
    actividad: f.actividad,
    proteccion: f.proteccion,
    fotografia: {
      url: f.fotografia,
      file: null
    },
    geom: f.geom
  }),
  'agente-patrimonial': f => ({
    nombre: f.nombre,
    tipologia: f.tipologia,
    actividad: f.actividad,
    naturaleza: f.naturaleza,
    provinciaId: f.provincia.id,
    municipioId: f.municipio.id,
    direccionPostal: f.direccionPostal,
    url: f.url,
    comentarios: f.comentarios,
    fotografia: {
      url: f.fotografia,
      file: null
    },
    geom: f.geom
  })
};

export const NEW_FEATURE_INITIAL_DATA = {
  'bien-inmueble': {
    nombre: '',
    tipologia: null,
    provinciaId: null,
    municipioId: null,
    periodoHistorico: null,
    caracter: '',
    tipologiaEspecifica: '',
    actividad: '',
    proteccion: null,
    fotografia: {
      url: null,
      file: null
    },
    geom: null
  },
  'bien-inmaterial': {
    nombre: '',
    tipologia: null,
    provinciaId: null,
    municipioId: null,
    periodoHistorico: null,
    caracter: '',
    tipologiaEspecifica: '',
    actividad: '',
    proteccion: null,
    fotografia: {
      url: null,
      file: null
    },
    geom: null
  },
  'agente-patrimonial': {
    nombre: '',
    tipologia: null,
    actividad: null,
    naturaleza: null,
    provinciaId: null,
    municipioId: null,
    direccionPostal: '',
    url: '',
    comentarios: '',
    fotografia: {
      url: null,
      file: null
    },
    geom: null
  }
};