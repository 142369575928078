import React from 'react';
import classes from './FieldCommon.module.scss';

function TextField({ label, value }) {
  return (
    <div className={classes.container}>
      <label className={classes.label}>{label}</label>
      <p className={classes.value}>{value || '-'}</p>
    </div>
  );
}

export default TextField;