import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import {
  selectLocationEditor, activateLocationEditor, resetLocationEditor
} from '../../mapSlice';
import classes from './LocationEditor.module.scss';
import { ReactComponent as LocationIcon } from 'assets/svg/location.svg';

const MESSAGES = {
  'empty': 'Añade la ubicación del elemento',
  'idle': 'Cambia la ubicación del elemento',
  'active': 'Haz click en el mapa para elegir la nueva ubicación',
  'selected': 'Nueva ubicación seleccionada'
};

function LocationEditor({ value, onChange }) {
  const { active, location } = useSelector(selectLocationEditor);
  const lastLocation = useRef(null);
  const dispatch = useDispatch();

  const status = active ? 'active' : location ? 'selected' : value ? 'idle' : 'empty';

  useEffect(() => {
    return () => dispatch(resetLocationEditor());
  }, [dispatch]);

  useEffect(() => {
    if (location) {
      onChange(location);
      lastLocation.current = location;
    }
  }, [location, onChange]);

  return (
    <div className={classes.container}>
      <button
        className={classes.locationButton}
        type='button'
        onClick={() => dispatch(activateLocationEditor())}
      >
        <LocationIcon className={cx(classes.locationIcon, classes[status])} />
      </button>
      <span className={cx(classes.message, classes[status])}>
        {MESSAGES[status]}
      </span>
    </div>
  );
}

export default LocationEditor;