import React from 'react';
import classes from './FeatureLayout.module.scss';

function FeatureLayout({ title, children }) {
  return (
    <div>
      <h2 className={classes.title}>{title}</h2>
      <div className={classes.content}>
        {children}
      </div>
    </div>
  );
}

export default FeatureLayout;