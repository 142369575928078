import { useRef, useEffect } from 'react';

function useOutsideClick({ active, handler }) {
  const ref = useRef(null);

  useEffect(() => {
    if (!active) return;
    
    const handleClickOutside = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        handler();
      }
    };

    // Delay the subscription to avoid synchronous event flushing
    // https://github.com/facebook/react/issues/24657
    setTimeout(() => {
      document.addEventListener('click', handleClickOutside, { once: true });
    });

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [active, handler]);

  return ref;
}

export default useOutsideClick;