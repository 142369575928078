import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectProvinces, selectMunicipalities, fetchProvinces, fetchMunicipalities
} from '../mapSlice';

function useProvincesMunicipalities(provId, muniId) {
  const provinces = useSelector(selectProvinces);
  const municipalities = useSelector(state => selectMunicipalities(state, provId));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!provinces) {
      dispatch(fetchProvinces());
    }
  }, [provinces, dispatch]);

  useEffect(() => {
    if (!provinces) return;
    if (provId && !municipalities) {
      dispatch(fetchMunicipalities(provId));
    }
  }, [provId, municipalities, provinces, dispatch]);

  return { provinces, municipalities };
}

export default useProvincesMunicipalities;