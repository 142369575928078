import { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import {
	selectFullMap, selectCurrentBasemap, setStyleLoaded, setBasemapStyle
} from '../mapSlice';

function useMap({ mapContainer, selectedRef }) {
  const fullMap = useSelector(selectFullMap);
	const currentBasemap = useSelector(selectCurrentBasemap);
	const dispatch = useDispatch();
	
	const map = useRef(null);

	/**
   * Map creation useEffect
   */
	useEffect(() => {
		mapboxgl.accessToken = fullMap.mapboxToken;

		map.current = new mapboxgl.Map({
      container: mapContainer.current,
      ...fullMap.config.initialMap
    });

    map.current.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        collapsed: true,
        language: 'es',
        placeholder: 'Búsqueda'
      })
    );
		
    map.current.addControl(new mapboxgl.AttributionControl({ customAttribution: ['Developed by <a href="https://cartometrics.com" target="_blank"><strong>Cartometrics</strong></a>'] }), 'bottom-right');
    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

    // First style loaded
		map.current.on('load', () => {
      dispatch(setStyleLoaded(true));

      // map.current.on('moveend', () => {
      //   console.log(map.current.getZoom(), map.current.getCenter());
      // })
		});

		/* Clean-up */
		return () => {
			map.current.remove();
			map.current = null;
		};
	}, [fullMap, mapContainer, dispatch]);


	/**
   * Basemap change useEffect
   */
	useEffect(() => {
    if (!currentBasemap) return;

    dispatch(setBasemapStyle({
      style: currentBasemap.style, map, fullMap, selectedRef
    }));
	}, [fullMap, currentBasemap, dispatch, map, selectedRef]);

	return map;
}

export default useMap;