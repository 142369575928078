import React, { useState, useLayoutEffect, useRef } from 'react';
import cx from 'classnames';
import classes from './ImageInputField.module.scss';
import noImage from 'assets/svg/no-image.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/x.svg';

function ImageInputField({ label, value, height = '150px', onChange, onDelete }) {
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const inputRef = useRef(null);
  
  useLayoutEffect(() => {
    setShow(false);
    setError(false);
  }, [value]);

  function handleDelete(e) {
    e.preventDefault();
    onDelete();
  }

  return (
    <div className={classes.container}>
      <div className={classes.label}>{label}</div>
        <div className={classes.imageContainer}>
          <img
            className={cx(classes.image, { [classes.show]: show })}
            style={{ height }}
            src={(value && !error) ? value : noImage}
            alt={label}
            onLoad={() => setShow(true)}
            onError={e => setError(true)}
            onClick={() => inputRef.current.click()}
          />
          {value && (
            <button
              className={classes.deleteButton}
              type='button'
              onClick={handleDelete}
            >
              <DeleteIcon className={classes.deleteIcon} />
            </button>
          )}
          <input
            className={classes.input}
            type='file'
            ref={inputRef}
            onChange={onChange}
          />
        </div>
    </div>
  );
}

export default ImageInputField;