import React from 'react';
import {
  TIPOLOGIA_AGENTE_PATRIMONIAL, ACTIVIDAD_AGENTE_PATRIMONIAL, NATURALEZA_ACTIVIDAD_AGENTE
} from 'config/layers';
import TextField from 'features/common/ui/Field/TextField';
import LinkField from 'features/common/ui/Field/LinkField';
import ImageField from 'features/common/ui/Field/ImageField';

function AgentePatrimonialView({ data }) {
  return (
    <div>
      <TextField label='Nombre' value={data.nombre} />
      <TextField label='Tipología' value={TIPOLOGIA_AGENTE_PATRIMONIAL[data.tipologia]} />
      <TextField label='Actividad' value={ACTIVIDAD_AGENTE_PATRIMONIAL[data.actividad]} />
      <TextField label='Naturaleza' value={NATURALEZA_ACTIVIDAD_AGENTE[data.naturaleza]} />
      <TextField label='Provincia' value={data.provincia.name} />
      <TextField label='Municipio' value={data.municipio.name} />
      <TextField label='Dirección postal' value={data.direccionPostal} />
      <LinkField label='URL' value={data.url} />
      <TextField label='Comentarios' value={data.comentarios} />
      <ImageField label='Fotografía' value={data.fotografia} />
    </div>
  );
}

export default AgentePatrimonialView;