import React, { useRef } from 'react';
import useMap from './useMap';
import useLayers from './useLayers';
import useFeatures from './useFeatures';

const mapContainerStyle = {
	position: 'absolute',
	top: 0,
	bottom: 0,
	height: '100%',
	width: '100%'
};

function Map({ project }) {
	const mapContainer = useRef(null);
	const hoveredRef = useRef(null);
	const selectedRef = useRef(null);

	/**
   * useMap hook: map creation & basemap change
   */
	const map = useMap({ mapContainer, selectedRef });

	/**
   * useLayers hook: layers creation, event handlers & layers visibility
   */
	useLayers({ map, hoveredRef, selectedRef });

	/**
   * useFeatures hook: layer features selection
   */
	useFeatures({ map, hoveredRef, selectedRef });

	return (
    <div ref={mapContainer} style={mapContainerStyle}></div>
  );
}

export default Map;